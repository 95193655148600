// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Csrf from "./Csrf.res.js";
import * as Http from "./Http.res.js";
import * as $$Promise from "../bindings/Promise.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../loggers/SentryLogger.res.js";
import * as Json$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json.res.js";

function $$fetch$1(url, req, res, err, param) {
  var tmp;
  var exit = 0;
  exit = typeof req === "object" && !(req.NAME === "Delete" && req.VAL === undefined) ? 1 : 2;
  switch (exit) {
    case 1 :
        if (typeof res === "object") {
          var variant = res.NAME;
          tmp = variant === "Text" || variant === "Json" ? [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "Content-Type",
                Http.ContentType.toString("Json")
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ] : [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "Content-Type",
                Http.ContentType.toString("Json")
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ];
        } else {
          tmp = [
            [
              "Content-Type",
              Http.ContentType.toString("Json")
            ],
            [
              "X-CSRF-Token",
              Belt_Option.getExn(Csrf.token())
            ]
          ];
        }
        break;
    case 2 :
        if (typeof res === "object") {
          var variant$1 = res.NAME;
          tmp = variant$1 === "Text" || variant$1 === "Json" ? [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ] : [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ];
        } else {
          tmp = [[
              "X-CSRF-Token",
              Belt_Option.getExn(Csrf.token())
            ]];
        }
        break;
    
  }
  return $$Promise.$$catch($$Promise.andThen(fetch("/api/v1" + url, {
                      method: Http.Method.toFetch(req),
                      body: Belt_Option.map(Http.Method.body(req), (function (body) {
                              return JSON.stringify(body);
                            })),
                      headers: Caml_option.some(new Headers(tmp)),
                      credentials: "same-origin"
                    }), (function (response) {
                    var match = response.ok;
                    var exit = 0;
                    if (typeof res === "object") {
                      var variant = res.NAME;
                      if (variant === "Json") {
                        if (match) {
                          var decoder = res.VAL;
                          return $$Promise.map(response.json(), (function (json) {
                                        return {
                                                TAG: "Ok",
                                                _0: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder))
                                              };
                                      }));
                        }
                        exit = 1;
                      } else if (variant === "Text") {
                        if (match) {
                          var parse = res.VAL;
                          return $$Promise.map(response.text(), (function (text) {
                                        return {
                                                TAG: "Ok",
                                                _0: parse(text)
                                              };
                                      }));
                        }
                        exit = 1;
                      } else {
                        if (match) {
                          var parse$1 = res.VAL;
                          return $$Promise.map(response.blob(), (function (blob) {
                                        return {
                                                TAG: "Ok",
                                                _0: parse$1(blob)
                                              };
                                      }));
                        }
                        exit = 1;
                      }
                    } else {
                      if (match) {
                        return $$Promise.resolve({
                                    TAG: "Ok",
                                    _0: undefined
                                  });
                      }
                      exit = 1;
                    }
                    if (exit === 1) {
                      var match$1 = Http.ContentType.fromHeaders(response.headers);
                      var exit$1 = 0;
                      if (match$1 !== undefined && typeof match$1 !== "object") {
                        switch (match$1) {
                          case "Text" :
                              return $$Promise.map(response.text(), (function (text) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "text"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    text
                                                  ]);
                                              return {
                                                      TAG: "Error",
                                                      _0: "Opaque"
                                                    };
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "text"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    text
                                                  ]);
                                              if (err !== undefined && typeof err === "object") {
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          NAME: "Message",
                                                          VAL: Belt_Result.getExn(Json$JsonCombinators.decode(JSON.parse(text), err.VAL))
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        TAG: "Error",
                                                        _0: "Opaque"
                                                      };
                                              }
                                            }
                                          }));
                          case "Json" :
                              return $$Promise.map(response.json(), (function (json) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "json"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    json
                                                  ]);
                                              return {
                                                      TAG: "Error",
                                                      _0: "Opaque"
                                                    };
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "json"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    json
                                                  ]);
                                              if (err !== undefined && typeof err === "object") {
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          NAME: "Message",
                                                          VAL: Belt_Result.getExn(Json$JsonCombinators.decode(json, err.VAL))
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        TAG: "Error",
                                                        _0: "Opaque"
                                                      };
                                              }
                                            }
                                          }));
                          case "Blob" :
                              return $$Promise.map(response.blob(), (function (blob) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.status
                                                  ], [
                                                    "contentType",
                                                    "blob"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    blob
                                                  ]);
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetch",
                                                    fullPath: "Rest.fetch"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.status
                                                  ], [
                                                    "contentType",
                                                    "blob"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    blob
                                                  ]);
                                            }
                                            return {
                                                    TAG: "Error",
                                                    _0: "Opaque"
                                                  };
                                          }));
                          
                        }
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        var code = response.status;
                        if (Http.$$Error.server(code)) {
                          SentryLogger.warn6({
                                rootModule: "Rest",
                                subModulePath: /* [] */0,
                                value: "fetch",
                                fullPath: "Rest.fetch"
                              }, "Fetch::RequestError", [
                                "url",
                                url
                              ], [
                                "method",
                                Http.Method.toString(req)
                              ], [
                                "code",
                                code
                              ], [
                                "status",
                                response.statusText
                              ], [
                                "contentType",
                                "N/A"
                              ], [
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        } else {
                          SentryLogger.error6({
                                rootModule: "Rest",
                                subModulePath: /* [] */0,
                                value: "fetch",
                                fullPath: "Rest.fetch"
                              }, "Fetch::RequestError", [
                                "url",
                                url
                              ], [
                                "method",
                                Http.Method.toString(req)
                              ], [
                                "code",
                                code
                              ], [
                                "status",
                                response.statusText
                              ], [
                                "contentType",
                                "N/A"
                              ], [
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        }
                        return $$Promise.resolve({
                                    TAG: "Error",
                                    _0: "Opaque"
                                  });
                      }
                      
                    }
                    
                  })), (function (error) {
                SentryLogger.error4({
                      rootModule: "Rest",
                      subModulePath: /* [] */0,
                      value: "fetch",
                      fullPath: "Rest.fetch"
                    }, "Fetch::RequestError", [
                      "url",
                      url
                    ], [
                      "method",
                      Http.Method.toString(req)
                    ], [
                      "requestBody",
                      Http.Method.body(req)
                    ], [
                      "error",
                      error
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: "Opaque"
                          });
              }));
}

function fetchRoot(url, req, res, err, param) {
  var tmp;
  var exit = 0;
  exit = typeof req === "object" && !(req.NAME === "Delete" && req.VAL === undefined) ? 1 : 2;
  switch (exit) {
    case 1 :
        if (typeof res === "object") {
          var variant = res.NAME;
          tmp = variant === "Text" || variant === "Json" ? [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "Content-Type",
                Http.ContentType.toString("Json")
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ] : [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "Content-Type",
                Http.ContentType.toString("Json")
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ];
        } else {
          tmp = [
            [
              "Content-Type",
              Http.ContentType.toString("Json")
            ],
            [
              "X-CSRF-Token",
              Belt_Option.getExn(Csrf.token())
            ]
          ];
        }
        break;
    case 2 :
        if (typeof res === "object") {
          var variant$1 = res.NAME;
          tmp = variant$1 === "Text" || variant$1 === "Json" ? [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ] : [
              [
                "Accept",
                Http.ContentType.toString(Http.$$Response.toContentType(res))
              ],
              [
                "X-CSRF-Token",
                Belt_Option.getExn(Csrf.token())
              ]
            ];
        } else {
          tmp = [[
              "X-CSRF-Token",
              Belt_Option.getExn(Csrf.token())
            ]];
        }
        break;
    
  }
  return $$Promise.$$catch($$Promise.andThen(fetch(url, {
                      method: Http.Method.toFetch(req),
                      body: Belt_Option.map(Http.Method.body(req), (function (body) {
                              return JSON.stringify(body);
                            })),
                      headers: Caml_option.some(new Headers(tmp)),
                      credentials: "same-origin"
                    }), (function (response) {
                    var match = response.ok;
                    var exit = 0;
                    if (typeof res === "object") {
                      var variant = res.NAME;
                      if (variant === "Json") {
                        if (match) {
                          var decoder = res.VAL;
                          return $$Promise.map(response.json(), (function (json) {
                                        return {
                                                TAG: "Ok",
                                                _0: Belt_Result.getExn(Json$JsonCombinators.decode(json, decoder))
                                              };
                                      }));
                        }
                        exit = 1;
                      } else if (variant === "Text") {
                        if (match) {
                          var parse = res.VAL;
                          return $$Promise.map(response.text(), (function (text) {
                                        return {
                                                TAG: "Ok",
                                                _0: parse(text)
                                              };
                                      }));
                        }
                        exit = 1;
                      } else {
                        if (match) {
                          var parse$1 = res.VAL;
                          return $$Promise.map(response.blob(), (function (blob) {
                                        return {
                                                TAG: "Ok",
                                                _0: parse$1(blob)
                                              };
                                      }));
                        }
                        exit = 1;
                      }
                    } else {
                      if (match) {
                        return $$Promise.resolve({
                                    TAG: "Ok",
                                    _0: undefined
                                  });
                      }
                      exit = 1;
                    }
                    if (exit === 1) {
                      var match$1 = Http.ContentType.fromHeaders(response.headers);
                      var exit$1 = 0;
                      if (match$1 !== undefined && typeof match$1 !== "object") {
                        switch (match$1) {
                          case "Text" :
                              return $$Promise.map(response.text(), (function (text) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "text"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    text
                                                  ]);
                                              return {
                                                      TAG: "Error",
                                                      _0: "Opaque"
                                                    };
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "text"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    text
                                                  ]);
                                              if (err !== undefined && typeof err === "object") {
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          NAME: "Message",
                                                          VAL: Belt_Result.getExn(Json$JsonCombinators.decode(JSON.parse(text), err.VAL))
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        TAG: "Error",
                                                        _0: "Opaque"
                                                      };
                                              }
                                            }
                                          }));
                          case "Json" :
                              return $$Promise.map(response.json(), (function (json) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "json"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    json
                                                  ]);
                                              return {
                                                      TAG: "Error",
                                                      _0: "Opaque"
                                                    };
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.statusText
                                                  ], [
                                                    "contentType",
                                                    "json"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    json
                                                  ]);
                                              if (err !== undefined && typeof err === "object") {
                                                return {
                                                        TAG: "Error",
                                                        _0: {
                                                          NAME: "Message",
                                                          VAL: Belt_Result.getExn(Json$JsonCombinators.decode(json, err.VAL))
                                                        }
                                                      };
                                              } else {
                                                return {
                                                        TAG: "Error",
                                                        _0: "Opaque"
                                                      };
                                              }
                                            }
                                          }));
                          case "Blob" :
                              return $$Promise.map(response.blob(), (function (blob) {
                                            var code = response.status;
                                            if (Http.$$Error.server(code)) {
                                              SentryLogger.warn7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.status
                                                  ], [
                                                    "contentType",
                                                    "blob"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    blob
                                                  ]);
                                            } else {
                                              SentryLogger.error7({
                                                    rootModule: "Rest",
                                                    subModulePath: /* [] */0,
                                                    value: "fetchRoot",
                                                    fullPath: "Rest.fetchRoot"
                                                  }, "Fetch::RequestError", [
                                                    "url",
                                                    url
                                                  ], [
                                                    "method",
                                                    Http.Method.toString(req)
                                                  ], [
                                                    "code",
                                                    code
                                                  ], [
                                                    "status",
                                                    response.status
                                                  ], [
                                                    "contentType",
                                                    "blob"
                                                  ], [
                                                    "requestBody",
                                                    Http.Method.body(req)
                                                  ], [
                                                    "responseBody",
                                                    blob
                                                  ]);
                                            }
                                            return {
                                                    TAG: "Error",
                                                    _0: "Opaque"
                                                  };
                                          }));
                          
                        }
                      } else {
                        exit$1 = 2;
                      }
                      if (exit$1 === 2) {
                        var code = response.status;
                        if (Http.$$Error.server(code)) {
                          SentryLogger.warn6({
                                rootModule: "Rest",
                                subModulePath: /* [] */0,
                                value: "fetchRoot",
                                fullPath: "Rest.fetchRoot"
                              }, "Fetch::RequestError", [
                                "url",
                                url
                              ], [
                                "method",
                                Http.Method.toString(req)
                              ], [
                                "code",
                                code
                              ], [
                                "status",
                                response.statusText
                              ], [
                                "contentType",
                                "N/A"
                              ], [
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        } else {
                          SentryLogger.error6({
                                rootModule: "Rest",
                                subModulePath: /* [] */0,
                                value: "fetchRoot",
                                fullPath: "Rest.fetchRoot"
                              }, "Fetch::RequestError", [
                                "url",
                                url
                              ], [
                                "method",
                                Http.Method.toString(req)
                              ], [
                                "code",
                                code
                              ], [
                                "status",
                                response.statusText
                              ], [
                                "contentType",
                                "N/A"
                              ], [
                                "requestBody",
                                Http.Method.body(req)
                              ]);
                        }
                        return $$Promise.resolve({
                                    TAG: "Error",
                                    _0: "Opaque"
                                  });
                      }
                      
                    }
                    
                  })), (function (error) {
                SentryLogger.error4({
                      rootModule: "Rest",
                      subModulePath: /* [] */0,
                      value: "fetchRoot",
                      fullPath: "Rest.fetchRoot"
                    }, "Fetch::RequestError", [
                      "url",
                      url
                    ], [
                      "method",
                      Http.Method.toString(req)
                    ], [
                      "requestBody",
                      Http.Method.body(req)
                    ], [
                      "error",
                      error
                    ]);
                return $$Promise.resolve({
                            TAG: "Error",
                            _0: "Opaque"
                          });
              }));
}

export {
  $$fetch$1 as $$fetch,
  fetchRoot ,
}
/* Csrf Not a pure module */
